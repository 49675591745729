import React, {
    useEffect, useState,

} from 'react';
import Menu from '../../components/Menu';
import { Container, Body } from './styles';

import NotificacaoForm from '../../components/Notificacao';

import { Creators as NotificationActions } from '../../store/ducks/notifications';

import { INotification } from '../../interfaces';

import { consultartodasCampanhas } from '../../services/submit'
import TableNotificacao from '../../components/Table/notificacao/index'

const Notificacao: React.FC = () => {

    const [notificacoes, setNotificacoes] = useState<Array<INotification>>([]);



    useEffect(() => {
        async function loadNotifications(): Promise<void> {
            const response: any = await consultartodasCampanhas();
            if (response && response.data) {
                setNotificacoes(response.data.notificacoes)
            }
        }
        loadNotifications();
    }, []);

    return (

        <Container>
            <Menu campaign={false} notify={true} />
            <Body>
                <NotificacaoForm />
                <TableNotificacao notificacoes={notificacoes} />

            </Body>
        </Container>
    )
}

export default Notificacao;