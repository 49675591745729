import React, { useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Container, Content, Stars, LoginDiv } from './styles';
import { AiOutlineGooglePlus } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { IRootState } from '../../../interfaces';
import { Creators as CampaignActions } from '../../../store/ducks/campaign';
import Logo from '../../../assets/FINSOL.svg';
import Estrelas from '../../../assets/estrelas.svg';
import { permissaoRoute } from '../../../interfaces/Acesso'

const Login: React.FC = () => {
  let year = new Date().getFullYear();
  const { loginRequest } = CampaignActions;
  const dispatch = useDispatch();
  const history = useHistory();

  const clientId: string = process.env.REACT_APP_CLIENTID_GOOGLE || '';

  const { loading, isLogged, loginError } = useSelector(
    (state: IRootState) => state.campaign,
  );

  function handleLogin(response: any) {
    dispatch(loginRequest(response));
  }

  useEffect(() => {
    const routaPermissao = permissaoRoute();
    if (isLogged) history.push(routaPermissao);
  }, [isLogged]);

  useEffect(() => {
    if (loginError) {
      Swal.fire({
        icon: 'error',
        title: loginError,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [loginError]);

  return (
    <Container>
      <Content>
        <img src={Logo} alt="finsol-logo"></img>
        <p>Faça seu login.</p>

        <GoogleLogin
          render={renderProps => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              {loading ? (
                <LoginDiv>
                  <ReactLoading
                    type={'spin'}
                    color={'white'}
                    height={'10%'}
                    width={'10%'}
                  />
                </LoginDiv>
              ) : (
                <>
                  <p>SIGN IN WITH</p>
                  <AiOutlineGooglePlus
                    color="white"
                    size={30}
                  ></AiOutlineGooglePlus>
                </>
              )}
            </button>
          )}
          clientId={clientId}
          onSuccess={handleLogin}
          cookiePolicy={'single_host_origin'}
        />
      </Content>
      <Stars src={Estrelas} alt="finsol-logo"></Stars>

      <footer>© FINSOL all right reserved {year}</footer>
    </Container>
  );
};

export default Login;
