import styled from 'styled-components';
import colors from '../../colors';

export const Container = styled.button<{ url: string; done: boolean }>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  background-image: linear-gradient(
      180deg,
      #00000000 0%,
      #000000b3 71%,
      #000000f2 100%
    ),
    url(${props => '"'+ props.url + '"'});
  align-items: flex-end;
  border-radius: 0.7rem;
  width: 20vw;
  height: 25vh;
  opacity: ${props => (props.done ? 0.6 : 1)};
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const TextName = styled.h2<{ done: boolean }>`
  color: ${props => (props.done ? '#EEAD2D' : colors.text)};
  font-size: 1.8rem;
  font-weight: bold;
`;

export const TextInfo = styled.p<{ done: boolean }>`
  margin-top: 0.4rem;
  color: ${props => (props.done ? '#EEAD2D' : colors.text)};
  font-size: 1.5rem;
  font-weight: bold;
`;
