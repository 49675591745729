const colors = {
  primary: '#022751',
  secondary: '#FFCC00',
  tertiary: '#FFF',
  background: '#f6f7ff',
  text: '#FFFFFF',
  border: '#0000029',
};

export default colors;

