import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    AiOutlineSend,
} from 'react-icons/ai';
import {
    FormWrapper,
    NotificacaoDetailsDiv, Button, LoadingDiv, Container, Previsao
} from './style';
import { FormHandles } from '@unform/core';
import colors from '../../colors';
import * as Yup from 'yup';
import { enviarNotificacao, enviarImagemNotificacao } from '../../services/submit';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../interfaces';
import { Creators as NotificationActions } from '../../store/ducks/notifications';
import SpanError from '../LabelError';
import ReactLoading from 'react-loading';
import ReactQuill, { Quill } from 'react-quill'
import CustomTollbar from './toolbar/index'
import 'react-quill/dist/quill.snow.css'
import "quill-emoji/dist/quill-emoji.css";
import 'react-quill/dist/quill.bubble.css'
import { SpaceBarRounded } from '@material-ui/icons';
const emoji = require("quill-emoji");
Quill.register("modules/emoji", emoji);




const FormComponent: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { colabId } = useSelector((state: IRootState) => state.campaign);

    const inputRef = useRef<any>(null);
    let reactQuillRef = useRef<ReactQuill>(null);
    const buttonRef = useRef<any>(null);

    const formRef = useRef<FormHandles>(null);
    const [title, setTitle] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [inputError, setInputError] = useState<any>(false);
    const [textAreaError, setTextAreaError] = useState<any>(false);
    const [className, setClassName] = useState<any>('');
    const [loading, setLoading] = useState<any>(false);
    const [imagem, setImagem] = useState<{ url: string, file: File } | null>(null);
    const { getNotificationsRequest } = NotificationActions;

    async function reloadNotifications(): Promise<void> {
        dispatch(getNotificationsRequest());
    }

    const handleSubmit = async () => {

        let response: any;
        setLoading(true);
        setInputError(false)
        setTextAreaError(false)
        try {

            const schema = Yup.object().shape({

                texto: Yup.string().required('O campo Texto da Notificação precisa ser preenchido.')
            });



            const data = {
                titulo: title,
                texto: text,
                usuario: colabId,
                url: ''
            }

            await schema.validate(data, { abortEarly: false });
            console.log(text)


            Swal.fire({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter o envio!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3eae95',
                cancelButtonColor: '#e14c4c',
                confirmButtonText: 'Sim, estou ciente.',
                cancelButtonText: 'Não, cancelar.',
            }).then(async (result) => {

                if (result.isConfirmed) {

                    if (imagem != null) {
                        const res: any = await enviarImagemNotificacao(imagem.file);
                        data.url = res.data.url;
                    }
                    response = await enviarNotificacao(data);

                    if (response.error) {

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.message,
                        });

                    } else {

                        Swal.fire({
                            icon: 'success',
                            title: 'Notificação enviada com sucesso!',
                            showConfirmButton: false,
                            timer: 3000,
                        });

                        setTitle('');
                        setText('');
                        setInputError(false);
                        setTextAreaError(false);
                        setClassName('');
                        setImagem(null);

                    }


                    reloadNotifications();
                    buttonRef.current?.click();
                }
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                setClassName('empty-error');
                err.inner.forEach(object => {
                    if (object.path === 'titulo') {
                        setInputError(true);
                    }

                    if (object.path === 'texto') {
                        setTextAreaError(true);
                    }
                });
            } else if (err?.response?.data?.message) {
                Swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title:
                        'Não foi possível enviar a notificação, verifique os campos e tente novamente.',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }

    }

    useEffect(() => {
        buttonRef.current.click();
    }, [])

    const checkCharacterCount = (event: any) => {
        const quill = reactQuillRef.current?.getEditor();
        const limit = 300;
        if (quill) {
            quill.on("text-change", function (delta, old, source) {
                if (quill.getLength() && quill.getLength() > limit) {
                    quill.deleteText(limit, quill.getLength());
                }

            })
        }
    };


    const handlerTexto = (e: string): void => {
        setText(e)
    }

    const handlerInsertImage = (e: any) => {

        if (e.currentTarget && e.currentTarget.files) {
            console.log(e.currentTarget.files)

            const file = e.currentTarget.files[0];
            setImagem({
                url: URL.createObjectURL(file),
                file: file
            })
        }
    }
    const customImageHandler = () => {
        inputRef.current?.click();
    }

    const handlerRemoverImagem = () => {
        setImagem(null);
    }

    const formats = [
        "header",
        "font",
        "bold",
        "italic",
        "underline",
        "strike",
        "link",
        "list",
        "emoji"
    ]

    const modules = {

        toolbar: {
            container: "#toolbar",
        },
        "emoji-toolbar": true,
    }



    return (
        <NotificacaoDetailsDiv>

            {loading ? (
                <LoadingDiv>
                    <ReactLoading
                        type={'spin'}
                        color={colors.primary}
                        height={'5%'}
                        width={'5%'}
                    />
                </LoadingDiv>
            ) :
                (<FormWrapper ref={formRef} onSubmit={handleSubmit}>

                    <span >Texto da notificação: *</span>
                    <Container>
                        <div id="toolbar">

                            <span className="ql-formats">

                                <button className="ql-header" value="2" ref={buttonRef}></button>
                            </span>

                            <span className="ql-formats">
                                <button className="ql-bold" />
                                <button className="ql-italic" />
                                <button className="ql-underline" />
                                <button className="ql-strike" />
                            </span>
                            <span className="ql-formats">
                                <button className="ql-link" />
                                <button className="ql-list" value='bullet' />
                                <button className="ql-emoji" />
                            </span>
                        </div >
                        <ReactQuill
                            onKeyDown={checkCharacterCount}
                            ref={reactQuillRef}
                            formats={formats}
                            modules={modules}
                            value={text}
                            defaultValue={text}
                            onChange={handlerTexto}
                            placeholder='Digite aqui sua Notificação'
                        />
                    </Container>
                    {inputError && (<SpanError text="A noticicação deve conter um título preenchido." />)}

                    {textAreaError && (<SpanError text="A notificação precisa ser preenchido." />)}

                    <span >Previsão da notificação (a seção de imagens será exibida no App apenas caso alguma imagem seja anexada):</span>
                    <Previsao>
                        {imagem != null ? (
                            <div id='remover' >
                                <img src={imagem.url} alt='image' />
                                <p onClick={handlerRemoverImagem}>Remover</p>
                            </div>
                        ) : (
                            <div id='image' onClick={customImageHandler}>
                                <p>Clique aqui para inserir uma imagem (Opcional)</p>
                            </div>
                        )}
                        <div>
                            <ReactQuill
                                value={text}
                                theme='bubble'
                                readOnly
                            />
                        </div>
                        <input type="file" accept="image/*"
                            ref={inputRef}
                            style={{ display: "none" }}
                            onChange={handlerInsertImage} />
                    </Previsao>

                    <footer>
                        <Button onClick={handleSubmit} type="submit" >
                            <AiOutlineSend
                                color={colors.primary}
                                size={20}
                            />
                            <p>Enviar</p>
                        </Button>
                    </footer>
                </FormWrapper>

                )
            }
        </NotificacaoDetailsDiv >
    )
}

export default FormComponent;