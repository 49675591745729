import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Records from '../../components/Records';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface ITabProps {
  records: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    color: 'black',
    backgroundColor: theme.palette.background.paper,
    marginTop: '4rem',
    borderRadius: '4px',
    marginBottom: '3.5rem',
    boxShadow: '0px 8px 20px #222b4529',
  },
  Appbar: {
    color: '#000000CC',

    borderBox: 'none',
    backgroundColor: 'white',
  },
  Tabs: {},
  Tab: {
    color: '#000000CC',
    fontSize: '1.3rem',
    textTransform: 'none',
  },
  SelectedTab: {
    color: '#000000CC',
    fontSize: '1.3rem',
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: '0px 8px 20px #222b4529',
  },
}));

export default function SimpleTabs({ records }: ITabProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" className={classes.Appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              height: '0px',
            },
          }}
          className={classes.Tabs}
        >
          <Tab
            label="Registros Aceitos"
            {...a11yProps(0)}
            className={value === 0 ? classes.SelectedTab : classes.Tab}
          />
          <Tab
            label="Registros Reimportados"
            {...a11yProps(1)}
            className={value === 1 ? classes.SelectedTab : classes.Tab}
          />
          <Tab
            label="Registros Rejeitados"
            {...a11yProps(2)}
            className={value === 2 ? classes.SelectedTab : classes.Tab}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Records
          recordList={
            records?.retornoAdicaoCampanhaPessoas.listaCampanhaPessoaImportadas
          }
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Records
          recordList={
            records?.retornoAdicaoCampanhaPessoas
              ?.listaCampanhaPessoaReimportadas
          }
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Records recordList={records?.listaCampanhaPessoaInvalidadas} />
      </TabPanel>
    </div>
  );
}
