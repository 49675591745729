import styled from 'styled-components';
import {shade} from 'polished'
import colors from '../../../colors';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;

  background: ${colors.background};

  input:invalid {
    border: 1px solid red;
  }

  textarea:invalid {
    border: 1px solid red;
  }
`;

export const Body = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  background: ${colors.background};
  flex-direction: column;
  margin: 2rem 2rem 5rem 5rem;
`;

export const RowElementDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BackButtonDiv = styled.div`
  button {
    font-weight: bold;
    border-radius: 0.5rem;
    margin: 0 1rem 0 1rem;
    font-size: 1.3rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
    box-shadow: 0px 4px 7px #222b4529;
    background: ${colors.primary};
    color: ${colors.tertiary};
    &:hover {
      font-size:16px;
      background: ${shade(0.2, '#2E3180')}
  }

  p {
    margin-left: 1rem;
    font-weight: bold;
  }
`;

export const ActionButtonsDiv = styled.div`
  display: flex;
  margin-top: 3rem;
  button {
    display: flex;
    align-items: center;
    background: ${colors.tertiary};
    color: ${colors.primary};
    font-weight: bold;
    border-radius: 0.5rem;
    margin: 0 1rem 0 1rem;
    font-size: 1.3rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
    box-shadow: 0px 4px 7px rgba(182, 182, 182, 0.3);
    &:hover {
      background: ${shade(0.1, '#FFF')}
  }

  button:disabled {
    color: #707070;
  }

  p {
    margin-left: 1rem;
  }
`;

export const Divider = styled.div`
  display: flex;
  margin: 3rem 0 0 1rem;
  border-bottom: 2px solid rgba(182, 182, 182, 0.3);
  color: ${colors.primary};
  align-items: center;
  padding-bottom: 1rem;
`;

export const CampaignDetailsDiv = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  background: ${colors.tertiary};
  padding: 2rem 2rem 0;
  margin: 3rem 0 1rem 0;
  border-radius: 5px;
  box-shadow: 0px 8px 20px #222b4529;

  p {
    color: ${colors.primary};
    margin-bottom: 1rem;
    font-weight: lighter;
    font-size: 1rem;
    font: normal normal normal 1.4rem Roboto;
  }
`;

export const CampaignSituationDiv = styled(CampaignDetailsDiv)``;

export const ImagePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(182, 182, 182, 0.3);
  justify-content: space-between;
  align-items: center;

  input {
    display: none;
  }

  div {
    max-width: 50%;
  }

  label,
  button {
    cursor: pointer;
    background: ${colors.primary};
    color: ${colors.tertiary};
    font-size: 1rem;
    border-radius: 0.5rem;
    margin: 0 1rem 0 1rem;
    padding: 0.4rem 1.5rem 0.4rem 1.5rem;
    height: 3.3rem;
    align-items: center;
    font: normal normal bold 1.3rem Lato;
    &:hover {
      background: ${shade(0.2, '#2E3180')}
  }
  p:nth-child(2) {
    color: #707070;
  }
  a {
    color: #707070;
    font-size: 1.3rem;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 3.8rem;
  border-radius: 4px;
  background: #fff;
  color: #b5b5b5 !important;
  
  font: normal normal medium 1.5rem Lato;
  padding: 0 1rem 0 1rem;
  border: 1px solid #dededf;
`;

export const FormatDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;

  p:nth-child(2) {
    color: #707070;
    margin-left: 1rem;
  }

  div {
    display: flex;
    flex: 1;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 3.8rem;

    p:nth-child(2) {
      color: #707070;
    }
  }
`;

export const TwoInputsDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  justify-content: space-between;

  div {
    display: flex;
    width: 47%;
    flex-direction: column;
    align-items: flex-start;
    min-height: 3.8rem;

    input {
      display: flex;
      border-radius: 5px;
      color: #b5b5b5;
      height: 3.8rem;
      max-width: 100%;
      padding: 0.3rem 1rem 0.3rem 1rem;
      border: 1px solid #dededf;
      font: normal normal 300 1.4rem Roboto;
      &::placeholder {
        color: #b5b5b5;
      }
    }
  }
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid rgba(182, 182, 182, 0.3);

  p {
    margin: 1rem 0 0.2rem 0;
  }

  input {
    display: flex;
    border-radius: 5px;
    color: #b5b5b5;
    height: 3.8rem;
    width: 100%;
    padding: 0.3rem 1rem 0.3rem 1rem;
    border: 1px solid #dededf;
    font: normal normal 300 1.4rem Roboto;
    &::placeholder {
      color: #b7b7cc;
    }
  }
`;

export const UserInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
  border-bottom: 1px solid rgba(182, 182, 182, 0.3);

  div {
    display: flex;
    flex-direction: column;
  }

  p:nth-child(2) {
    color: #707070;
  }
`;

export const ShareInfoDiv = styled.div`
  display: flex;
  margin: 1rem 0 0 0;
  border-bottom: 1px solid rgba(182, 182, 182, 0.3);

  div {
    display: flex;
    p {
      margin-left: 1rem;
    }
  }

  div:first-child {
    margin-right: 1rem;
  }
`;

export const ImportDiv = styled.div`
  margin: 4rem 0 0 0;
  justify-content: center;
  align-items: center;
`;

export const ImportFileDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 8px 20px #222b4529;
  padding: 2.5rem 0 2.5rem 0;
  justify-content: center;
  background: ${colors.tertiary};
  align-items: center;
  margin-bottom: 3.5rem;

  div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #000000de;
    }
  }
  svg{
    margin-bottom:15px;
  }
`;

export const ConfirmImportButton = styled.button`
  display: flex;
  color: #fff;
  align-items:center:
  justify-content: flex-start;
  padding: 0.4rem 1rem 0.4rem 1rem;
  background: ${colors.primary};
  border-radius: 4px;
  font-size: 1.3rem;
 
  &:hover {
    font-size:16px;
    background: ${shade(0.1, '#2E3180')
  }

`;

export const ButtonsDiv = styled.div`
  display: flex;
  margin: 2rem 0 0 0;
  border-radius: 4px;

  input {
    display: none;
  }

  button,
  label {
    cursor: pointer;
    color: ${colors.primary};
    padding: 0.3rem 1rem 0.3rem 1rem;
    border-radius: 4px;
    border: 1px solid #022751;
    font-size: 1.3rem;
    margin-left: 4rem;
    &:hover {
      background: ${shade(0.1, '#FBFCFC')}
  }
`;

export const LoadingDiv = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const ErrorDiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  border: 1px solid red;
  color: red;
`;
