import React, {
    useState,
} from 'react';
import Menu from '../../components/Menu';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { Container, Body, BackButtonDiv, RelatDetailsDiv, Button, LoadingDiv } from './style';
import colors from '../../colors';
import {
    AiOutlineDownload,
} from 'react-icons/ai';
import { getRelatorio } from '../../services/submit';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';

const RelatorioAcessos: React.FC = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<any>(false);

    const [type, setType] = useState<string>('csv');

    function handleGoBack() {
        history.goBack();
    }

    function download(data: BlobPart, content_type: any) {

        const dateNow = format(new Date(), 'dd-MM-yyyy');

        const blob = new Blob([data], { type: content_type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `relatorio-${dateNow}.${type}`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

    async function handleSubmit() {
        let response: any;
        setLoading(true);

        try {
            response = await getRelatorio(type);

            if (response.error) {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.message,
                });

            } else {

                const { data, headers } = response;
                download(data, headers['content-type']);
            }

            setLoading(false);
            setType('csv')
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Não foi possível realizar o dowload do arquivo.',
            });
        }
    }

    // function selectOnlyThis(id: any) {
    //     var radio = document.getElementsByName('selectOne');
    //     Array.prototype.forEach.call(radio, function (el) {
    //         el.checked = false;
    //     });
    //     id.checked = true;
    // }

    return (
        <Container>
            <Menu campaign={false} notify={false} relatAcessos={true} />
            <Body>
                <header>
                    {/* <BackButtonDiv>
                        <button onClick={handleGoBack}>
                            <BiArrowBack color={colors.tertiary} size={20} />
                            <p>Voltar</p>
                        </button>
                    </BackButtonDiv> */}
                </header>
                <RelatDetailsDiv>

                    {loading ? (
                        <LoadingDiv>
                            <ReactLoading
                                type={'spin'}
                                color={colors.primary}
                                height={'5%'}
                                width={'5%'}
                            />
                        </LoadingDiv>
                    ) :
                        (
                            <>
                                <p>Selecionar tipo do arquivo a ser gerado:</p>
                                <div style={{ width: '100%', display: 'inline-block', paddingTop: '10px' }}>
                                    <div style={{ width: '50%', display: 'inline-block' }}>
                                        <input type="radio" id="xlsx" name="selectOne" className="radio-input"
                                            value="xlsx" onChange={e => setType(e.target.value)} />
                                        {/*  onClick={e => selectOnlyThis(e.target)} /> */}
                                        <label htmlFor="xlsx">.xlsx</label>
                                    </div>
                                    <div style={{ width: '30%', display: 'inline-block' }}>
                                        <input type="radio" id="csv" name="selectOne" checked className="radio-input"
                                            value="csv" onChange={e => setType(e.target.value)} />
                                        {/* onClick={e => selectOnlyThis(e.target)} /> */}
                                        <label htmlFor="csv">.csv</label>
                                    </div>
                                </div>
                                <footer>
                                    <Button onClick={handleSubmit} >
                                        <AiOutlineDownload
                                            color={colors.primary}
                                            size={20}
                                        />
                                        <p>Download</p>
                                    </Button>
                                </footer>
                            </>
                        )
                    }
                </RelatDetailsDiv>

            </Body>
        </Container>
    )
}

export default RelatorioAcessos;