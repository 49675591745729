import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import { ICampaignsObg, IProfileObj } from '../../../interfaces';

/**
 * Creating action types e creators
 */

export const { Types, Creators } = createActions({
  //login
  loginRequest: ['user'],
  loginSuccess: ['user', 'colabId'],
  loginFailure: ['error'],

  //log out
  logoutRequest: [],
  logoutSuccess: [],
  logoutFailure: [],

  //get campaigns
  getCampaignsRequest: [],
  getCampaignsSuccess: ['campaigns'],
  getCampaignsFailure: [],

  //add
  addCampaignRequest: ['campaign'],
  addCampaignSuccess: ['campaign'],
  addCampaignFailure: [],

  //update
  updateCampaignRequest: ['campaign', 'id'],
  updateCampaignSuccess: ['campaign'],
  updateCampaignFailure: [],

  //delete
  deleteCampaignRequest: ['id'],
  deleteCampaignSuccess: ['id'],
  deleteCampaignFailure: [],
});

/**
 * Creating the reducer handlers
 */
const INITIAL_STATE: ICampaignsObg = {
  campaigns: [],
  loading: false,
  isLogged: false,
  colabId: '',
  user: {},
  loginError: '',
};

// login
const loginRequest = (state = INITIAL_STATE, action: any): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = true;
    draft.loginError = '';
  });

const loginSuccess = (state = INITIAL_STATE, action: any): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
    draft.isLogged = true;
    draft.user = action.user;
    draft.colabId = action.colabId;
    draft.loginError = '';
  });

const loginFailure = (state = INITIAL_STATE, action: any): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
    draft.loginError = action.error.message;
  });

// logout
const logoutRequest = (state = INITIAL_STATE, action: any): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = true;
  });

const logoutSuccess = (state = INITIAL_STATE, action: any): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
    draft.isLogged = false;
    draft.user = {};
  });

const logoutFailure = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
  });

// get campaigns
const getCampaignsRequest = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = true;
  });

const getCampaignsSuccess = (
  state = INITIAL_STATE,
  action: any,
): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
    draft.campaigns = action.campaigns;
  });

const getCampaignsFailure = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
  });

// add campaign
const addCampaignRequest = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = true;
  });

const addCampaignSuccess = (
  state = INITIAL_STATE,
  action: any,
): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
    draft.campaigns = draft.campaigns.concat(action.campaign);
  });

const addCampaignFailure = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
  });

// update campaign
const updateCampaignRequest = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = true;
  });

const updateCampaignSuccess = (
  state = INITIAL_STATE,
  action: any,
): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
    draft.campaigns = draft.campaigns.filter(
      emp => emp.campanhaId !== action.campaign.campanhaId,
    );
    draft.campaigns = draft.campaigns.concat(action.campaign);
  });

const updateCampaignFailure = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
  });

// delete campaign
const deleteCampaignRequest = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = true;
  });

const deleteCampaignSuccess = (
  state = INITIAL_STATE,
  action: any,
): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
    draft.campaigns = draft.campaigns.filter(
      emp => emp.campanhaId !== action.campanhaId,
    );
  });

const deleteCampaignFailure = (state = INITIAL_STATE): ICampaignsObg =>
  produce(state, draft => {
    draft.loading = false;
  });

/**
 * Creating reducer
 */
export default createReducer(INITIAL_STATE, {
  //login
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  //logout
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,

  //get
  [Types.GET_CAMPAIGNS_REQUEST]: getCampaignsRequest,
  [Types.GET_CAMPAIGNS_SUCCESS]: getCampaignsSuccess,
  [Types.GET_CAMPAIGNS_FAILURE]: getCampaignsFailure,

  //add
  [Types.ADD_CAMPAIGN_REQUEST]: addCampaignRequest,
  [Types.ADD_CAMPAIGN_SUCCESS]: addCampaignSuccess,
  [Types.ADD_CAMPAIGN_FAILURE]: addCampaignFailure,

  //update
  [Types.UPDATE_CAMPAIGN_REQUEST]: updateCampaignRequest,
  [Types.UPDATE_CAMPAIGN_SUCCESS]: updateCampaignSuccess,
  [Types.UPDATE_CAMPAIGN_FAILURE]: updateCampaignFailure,

  //delete
  [Types.DELETE_CAMPAIGN_REQUEST]: deleteCampaignRequest,
  [Types.DELETE_CAMPAIGN_SUCCESS]: deleteCampaignSuccess,
  [Types.DELETE_CAMPAIGN_FAILURE]: deleteCampaignFailure,
});
