import styled from 'styled-components';
import {shade} from 'polished'
import colors from '../../../colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  background: ${colors.background};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.background};
  padding: 3rem 5rem 5rem 8rem;
  width: 80vw;
  min-height: 100vh;
`;

export const CampaignsDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 3.2rem;
  grid-column-gap: 7rem;
`;

export const NewCampaignDiv = styled.div`
  border-bottom: 1px solid rgba(182, 182, 182, 0.3);
  margin-bottom: 1.5rem;

  button {
    font-weight: bold;
    border-radius: 0.5rem;
    margin: 0 0 1rem 0;
    font-size: 1.3rem;
    padding: 0.4rem 1rem 0.4rem 1rem;
    box-shadow: 0px 4px 7px #222b4529;
    background: ${colors.primary};
    color: ${colors.tertiary};
    &:hover {
      font-size:14px;
  }

  p {
    margin-left: 1rem;
  }
`;

export const LoadingDiv = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const EmptyDiv = styled.div`
  display: flex;
  flex: 1;
  color: ${colors.primary};
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;
