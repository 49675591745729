// @ts-nocheck
import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import colors from '../../colors';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        //backgroundColor: 'black',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.primary,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.primary,
      },
      daySelected: {
        backgroundColor: colors.primary,
      },
      dayDisabled: {
        color: colors.border,
      },
      current: {
        color: 'red',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: colors.primary,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3d3d3d',
    borderRadius: '5px',
  },
  input: {
    display: 'flex',
    flex: 1,
    color: '#3d3d3d',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IProps {
  value: any;
  onChange: any;
  disabled?: boolean;
  disablePast?: boolean;
  format?: string;
  placeholder?: string;
}

const DatePickerr: React.FC<IProps> = ({ value, onChange, ...rest }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={materialTheme}>
      <DatePicker
        {...rest}
        value={value}
        onChange={onChange}
        className={classes.root}
        InputProps={{ className: classes.input }}
        disableUnderline={false}
      />
    </ThemeProvider>
  );
};

export default DatePickerr;
