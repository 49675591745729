import * as Excel from "exceljs";
import {saveAs} from 'file-saver'

export interface Headers{
    header:string,
    key:string,
    width: number
}

const getNomeCompletoPrimeiraLetraMaiusculo = (nome:string): string =>{
    
    const auxTexto = nome.toLowerCase().split(' ')

    const textoFormatado = auxTexto.map(palavra => {
      return palavra.charAt(0).toUpperCase() + palavra.slice(1)
    }).toString().replace(/[,]/gi, ' ')
    return textoFormatado
}

const gerarArquivoAuxXLS = async (sheetName:string, dados:Array<any>,
     headers: Array<Headers>, fileName:string) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const workbook =  new Excel.Workbook();
    workbook.creator = 'Sistema FINSOL'
    workbook.lastModifiedBy = 'Sistema FINSOL'
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet(sheetName, {
        pageSetup:{paperSize: 9, orientation:'landscape'}
      });

      worksheet.properties.outlineLevelCol = 2;
      worksheet.properties.defaultRowHeight = 15;
      
    worksheet.columns = headers as Excel.Column[];
     
    dados.forEach((dado) => {

        worksheet.addRow(
            dado
        )
    });

    const buffer = await workbook.xlsx.writeBuffer()
    
    const blob = new Blob([buffer], {type: fileType})

    saveAs(blob, `${fileName}.xlsx`)
}

const gerarArquivoXLS = async (sheetName:string, dados:Array<any>,
    headers: Array<Headers>, nameFile:string)=>{
     
        await gerarArquivoAuxXLS(
            sheetName,
            dados,
            headers,
            nameFile
            )
}


export {gerarArquivoXLS, getNomeCompletoPrimeiraLetraMaiusculo}


