import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Creators as CampaignActions } from '../store/ducks/campaign';

import { campanhaRoutes, notificacaoRoutes, relatorioAcessoRoutes } from '../services/api';
import store from '../store';
import colors from '../colors';
import { ListaFuncoes } from '../interfaces/Acesso'

interface PrivateRouteProps extends RouteProps {
  component: any;
  isPrivate: boolean;
  allowed?: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { loginRequest } = CampaignActions;
  const dispatch = useDispatch();

  const { isPrivate, allowed = 'MOD:EMPTY', component: Component, ...rest } = props;
  const { isLogged } = store.getState().campaign;
  const [loading, setLoading] = useState<boolean>(true);
  const token = sessionStorage.getItem('@Finsol-web/token');
  const user = sessionStorage.getItem('@Finsol-web/g-user');
  const funcoes = sessionStorage.getItem('@Finsol-web/func')
  const permissoes: string[] = funcoes && JSON.parse(funcoes) || ['MOD:EMPTY']
  const isAllowed = (allowed !== ListaFuncoes['MOD:EMPTY']) && permissoes.includes(allowed)

  useEffect(() => {
    if (token) {
      campanhaRoutes.defaults.headers.common['x-access-token'] = token;
      notificacaoRoutes.defaults.headers.common['x-access-token'] = token;
      relatorioAcessoRoutes.defaults.headers.common['x-access-token'] = token;
      setLoading(false);
    } else if (user) {
      dispatch(loginRequest(JSON.parse(user)));
    }
  }, [token, user, loginRequest, dispatch]);

  return (
    <>
      {loading ? (
        <ReactLoading
          type={'bubbles'}
          color={colors.primary}
          height={'8%'}
          width={'8%'}
        />
      ) : (
        <Route
          {...rest}
          render={({ location }) =>
            (isPrivate === isLogged || token) && isAllowed ? (
              <Component />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

export default PrivateRoute;
