import React from 'react';
import { Container, Body, TextName, TextInfo } from './styles';
import { format } from 'date-fns';
import { ICampaign } from '../../interfaces';

interface IProps {
  campaign: ICampaign;
  openCampaign: (id: number) => void;
}

const CampaignItem: React.FC<IProps> = ({ campaign, openCampaign }: IProps) => {
  const done = campaign?.status === 'F';
  function handleSituation() {
    switch (campaign?.status) {
      case 'E':
        return 'Em Elaboração';
      case 'S':
        return 'Enviada';
      case 'F':
        return 'Finalizado';
      default:
        return 'Em Elaboração';
    }
  }

  return (
    <Container
      onClick={() => openCampaign(campaign?.campanhaId)}
      url={campaign?.mosaicoUrl}
      done={done}
    >
      <Body>
        <TextName done={done}>{`${campaign?.nome}`}</TextName>
        <TextInfo done={done}>Situação: {handleSituation()}</TextInfo>
        <TextInfo done={done}>
          Data de Início: {format(new Date(campaign?.dtInicio), 'dd/MM/yyyy')}{' '}
        </TextInfo>
        <TextInfo done={done}>
          Data de fim: {format(new Date(campaign?.dtFim), 'dd/MM/yyyy')}
        </TextInfo>
      </Body>
    </Container>
  );
};

export default CampaignItem;
