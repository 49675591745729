import { notificacaoRoutes, relatorioAcessoRoutes, prospeccaoRoutes } from './api';

export const enviarNotificacao = async ( data: any ) => {
    try {

        return await notificacaoRoutes.post(``, data);
    } catch (error) {
        return {
            error : true,
            message: 'Não foi possível enviar a notificação, entre em contato com o suporte e tente novamente em instantes.'
        }
    }
}

export const consultartodasCampanhas = async ( ) => {
    try {

        return await notificacaoRoutes.get('/');
    } catch (error) {
        return {
            error : true,
            message: 'Não foi possível enviar a notificação, entre em contato com o suporte e tente novamente em instantes.'
        }
    }
}

export const enviarImagemNotificacao = async ( file: File ) => {
    try {
        const formData = new FormData();
        formData.append('recfile', file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        return await notificacaoRoutes.post(`/imagem`,formData,config);
    } catch (error) {
        return {
            error : true,
            message: 'Não foi possível enviar a notificação, entre em contato com o suporte e tente novamente em instantes.'
        }
    }
}



export const getRelatorio = async ( type: any ) => {
    try {

        return await relatorioAcessoRoutes.get(`/user?tipo=${type}`, { responseType: 'arraybuffer' });
    } catch (error) {
        return {
            error : true,
            message: 'Não foi possível realizar o download do arquivo.'
        }
    }
}

export const consultarProspeccao = async ( data: any ) => {
    try {

        return await prospeccaoRoutes.post('', data);
    } catch (error) {
        return {
            error : true,
            message: 'Não foi possível consultar as prospecções, tente novamente em instantes.'
        }
    }
}

export const consultarInfoFiltro = async ( id: string ) => {
    try {

        return await prospeccaoRoutes.get(`/unidades/${id}`);
    } catch (error) {
        return {
            error : true,
            message: 'Não foi possível consultar as prospecções, tente novamente em instantes.'
        }
    }
}

export const getRelatorioProspeccao = async (data:any) => {
    try {

        return await relatorioAcessoRoutes.post(`/prospeccao`, data);
    } catch (error) {
        return {
            error : true,
            message: 'Não foi possível realizar o download do arquivo.'
        }
    }
}