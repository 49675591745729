import axios from 'axios';

const campanhaRoutes = axios.create({
  baseURL: `${process.env.REACT_APP_CAMPAIGN_API_URL}/campanha`,
});

const notificacaoRoutes = axios.create({
  baseURL: `${process.env.REACT_APP_CAMPAIGN_API_URL}/notificacao`,
});

const relatorioAcessoRoutes = axios.create({
  baseURL: `${process.env.REACT_APP_RELATORIO_API_URL}/relatorio`,
});

const prospeccaoRoutes = axios.create({
  baseURL: `${process.env.REACT_APP_CAMPAIGN_API_URL}/prospeccao`,
});

export { campanhaRoutes, notificacaoRoutes, relatorioAcessoRoutes,prospeccaoRoutes };
