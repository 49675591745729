import styled from 'styled-components';
import {shade} from 'polished'
import colors from '../../colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  background: ${colors.background};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.background};
  padding: 3rem 5rem 5rem 8rem;
  width: 80vw;
  min-height: 100vh;
  h2 {
    font-weight:bold;
    color: ${colors.primary}
  }
`;

export const BackButtonDiv = styled.div`
  button {
    font-weight: bold;
    border-radius: 0.5rem;
    margin: 0 1rem 0 1rem;
    font-size: 1.3rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
    box-shadow: 0px 4px 7px #222b4529;
    background: ${colors.primary};
    color: ${colors.tertiary};
  }

  p {
    margin-left: 1rem;
    font-weight: bold;
  }
`;

export const RelatDetailsDiv = styled.div`
  min-height: 90px;
  display: flex;
  width: 350px;
  flex-direction: column;
  background: ${colors.tertiary};
  padding: 2rem 2rem 0;
  margin: 3rem 0 1rem 0;
  border-radius: 5px;
  box-shadow: 0px 8px 20px #222b4529;
  font-size: 1.7rem;

  color: ${colors.primary};

  .radio-input{
    margin-right: 1rem;
  }

  footer {
    align-self: flex-end;
  }
`;

export const LoadingDiv = styled.div`
  display: flex;
  flex: 1;
  height: 50%;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    background: ${colors.tertiary};
    color: ${colors.primary};
    font-weight: bold;
    border-radius: 0.5rem;
    margin: 1rem 1rem 1rem 1rem;
    font-size: 1.3rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
    box-shadow: 1px 2px 7px 3px #222b4529;

    p {
        margin-left: 1rem;
        font-weight: bold;
        margin-top: 0.1rem;
    }
    &:hover{
      background: ${shade(0.1, '#FFF')}
    }
`;