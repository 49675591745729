import styled from 'styled-components';
import colors from '../../../colors';

export const Container = styled.div`
  box-shadow: 0px 8px 20px #222b4529;
  margin-top:20px;
  margin-bottom:50px;
  color: #000;
  width:100%;


  table{
      display:flex;
      flex-direction: column;
      font-size: 12px;

      tr{
      
        display:flex;
        align-items:center; 
        /* justify-content:space-between;  */
        box-shadow: 1px 2px 4px rgba(0,0,0,0.3);
        margin-top: 3px;
        padding-left:10px;
        padding-right:10px;

        /* td{ 
          border: 1px solid #FFCC00;
        } */
        /* div{
          justify-content:center;
        } */
        &:hover{
          font-size:12px;
          border: 1px solid #FFCC00;
        }


      }
  
      tr:first-child{
        display:flex;
        align-items:center;
        justify-content:space-around;
        padding-left: 15px;
        background: ${colors.primary};
        color: #ffff;
        text-align: center;
        height:1.5em;
        font-size: 15px;
        padding-right:10px;
      }

      p{
        display:flex;
        align-items:center;
        justify-content: center;
        margin-top: 15px;
        font-size: 16px;
      }

  

  }

`;
export const LoadingDiv = styled.div`
  display: flex;
  height: 6rem;
  justify-content: center;
  align-items: center;
`;