import styled from 'styled-components';
import {shade} from 'polished'
import colors from '../../colors';

export const Container = styled.div`
  display: flex;
  background: ${colors.primary};
  width: 12vw;
  flex-direction: column;
  padding: 1rem;
 
  
`;

export const Body = styled.div`
  margin-top:1em;
  padding: 3rem 2rem 3rem 2rem;
  position: fixed;
  height: 100%;
  header {
    display: flex;
    margin-bottom:1em;
    align-items:center;
    p {
      margin-top: 1rem;
      margin-left: 1rem;
    }

    img {
      height: 6rem;
      width: 6rem;
      border-radius: 3rem;
    }
  }
`;

export const MenuText = styled.p`
  font-weight: bold;
  opacity: 75%;
 
`;

export const MenuDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3rem 0 3rem 0;
  align-items: center;
 
  p {
    font-weight: lighter;
    color: ${props => props.color};
    margin-left: 1rem;
  }
`;

export const MenuButton = styled.button`
  margin: 3rem 0 3rem 0;
  justify-content: center;
  align-items: center;
  
  p {
    font-weight: lighter;
    color: ${props => props.color};
    margin-left: 1rem;
  }
  &:hover {
   font-size: 19px;
  
  }

  
`;

export const LoadingDiv = styled.div`
  display: flex;
 
  justify-content: center;
  align-items: center;
`;
