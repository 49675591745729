import styled from 'styled-components';
import colors from '../../../colors';
import ReactModal from 'react-modal';

export const Container = styled(ReactModal)`
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  marginright: '-50%';
  transform: 'translate(-50%, -50%)';
  background: 'white';
  color: '#000000';
  borderradius: '8px';
  width: '400px';
  height: '30%';
  border: 'none';
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 6rem;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;
    height: 7rem;
    border-radius: 0.5rem;
    margin: 2rem 3rem 2rem 3rem;
    font-size: 2rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
    box-shadow: 0px 4px 7px #222b4529;
    background: ${colors.primary};
    color: ${colors.tertiary};
  }
`;
