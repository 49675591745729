import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html { 
  font-size: 62.5%;
  }
   * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    color: #FFF;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
  }

  p,h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
  color: #FFF;
}

body,
input,
button,
textarea {
  font: 500 1.6rem Poppins;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.swal2-popup {
  font-size: small;
}

`;
