import React, { useState, useEffect } from 'react';
import { FaListUl, FaRegEdit } from 'react-icons/fa';
import { HiChartBar, HiBookOpen } from 'react-icons/hi'
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { Creators as CampaignActions } from '../../store/ducks/campaign';
import colors from '../../colors';
import {
  Container,
  MenuButton,
  MenuText,
  Body,
  LoadingDiv,
} from './styles';
import { IRootState } from '../../interfaces';
import { permissoesMenu, ListaFuncoes } from '../../interfaces/Acesso'

interface IProps {
  campaign?: boolean;
  notify?: boolean;
  relatAcessos?: boolean;
  prospeccoes?: boolean;
}
const Menu: React.FC<IProps> = ({ campaign = false, notify = false, relatAcessos = false, prospeccoes = false }) => {
  const history = useHistory();
  const { logoutRequest } = CampaignActions;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useSelector((state: IRootState) => state.campaign);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  async function logout(): Promise<void> {
    try {
      dispatch(logoutRequest());
      history.replace(`/`);
    } catch (err) {
      console.log(err);
    }
  }

  async function toHome(): Promise<void> {
    try {
      history.push('/campaigns');
    } catch (err) {
      console.log(err);
    }
  }

  async function toNotificacao(): Promise<void> {
    try {
      history.push('/notify');
    } catch (err) {
      console.log(err);
    }
  }

  async function toRelatAcessos(): Promise<void> {
    try {
      history.push('/relatAcessos');
    } catch (err) {
      console.log(err);
    }
  }

  async function toProspeccoes(): Promise<void> {
    try {
      history.push('/prospeccao');
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container>
      {/* {loading ? (
        <LoadingDiv>
          <ReactLoading
            type={'spin'}
            color={'white'}
            height={'10%'}
            width={'10%'}
          />
        </LoadingDiv>
      ) : ( */}


      <Body>
        <header>
          <img src={user?.profileObj?.imageUrl || ''} alt="img"></img>{' '}
          <p>{user?.profileObj?.name || ''}</p>
        </header>
        <MenuText>MENU</MenuText>
        <div>
          {permissoesMenu(ListaFuncoes['MOD:CAMP']) &&
            <>
              <MenuButton onClick={toHome} color={campaign ? colors.secondary : 'white'}>
                <FaListUl size={20} color={campaign ? colors.secondary : 'white'} />
                <p>Campanhas</p>
              </MenuButton>
              <MenuButton onClick={toNotificacao} color={notify ? colors.secondary : 'white'}>
                <FaRegEdit size={20} color={notify ? colors.secondary : 'white'} />
                <p>Notificação</p>
              </MenuButton>
              <MenuButton onClick={toRelatAcessos} color={relatAcessos ? colors.secondary : 'white'}>
                <HiChartBar size={22} color={relatAcessos ? colors.secondary : 'white'} />
                <p>Relatório</p>
              </MenuButton>
            </>
          }
          {permissoesMenu(ListaFuncoes['MOD:PROSP']) &&
            <MenuButton onClick={toProspeccoes} color={prospeccoes ? colors.secondary : 'white'}>
              <HiBookOpen size={22} color={prospeccoes ? colors.secondary : 'white'} />
              <p>Prospecção</p>
            </MenuButton>
          }
          <MenuButton onClick={logout}>
            <RiLogoutCircleRLine size={22} />
            <p>Sair</p>
          </MenuButton>
        </div>
      </Body>
    </Container>
  );
};

export default Menu;
