import React, {
    useEffect, useState,

} from 'react';
import Menu from '../../components/Menu';
import { Container, Body, Filtro } from './styles';
import { useSelector } from 'react-redux';
import { IRootState } from '../../interfaces';
import { FiDownload } from 'react-icons/fi'
import { IProspeccao, IUnidades, IColaboradores, maskDocumento, maskTelefone } from '../../interfaces';
import { format, parseISO } from 'date-fns'
import { gerarArquivoXLS, getNomeCompletoPrimeiraLetraMaiusculo, Headers } from '../../interfaces/ArquivoXls'

import { consultarProspeccao, consultarInfoFiltro } from '../../services/submit'
import TableProspeccao from '../../components/Table/prospeccao/index'


interface Request {
    colabId: string,
    agencia?: string,
    agenteId?: string
}

const Notificacao: React.FC = () => {

    const { colabId } = useSelector((state: IRootState) => state.campaign);

    const [prospeccoesFiltrados, setProspeccoesFiltrados] = useState<Array<IProspeccao>>([]);
    const [prospeccoes, setProspeccoes] = useState<Array<IProspeccao>>([]);

    const [unidades, setUnidades] = useState<Array<IUnidades>>([]);
    const [agentes, setAgentes] = useState<Array<IColaboradores>>([]);

    const [agenteFiltrado, setAgenteFiltrado] = useState(null);
    const [unidadeFiltrada, setUnidadeFiltrada] = useState(null);
    const [isFiltro, setIsFiltro] = useState<boolean>(false)

    const handlerFiltrarPorAgentes = (e: any) => {
        setAgenteFiltrado(e)
    }

    const handlerFiltrarPorUnidade = (e: any) => {
        setUnidadeFiltrada(e)
    }



    const handlerExtrairRelatorio = async () => {

        const cabecalho: Array<Headers> = [
            { header: 'Nome Completo', key: 'nome', width: 35 },
            { header: 'Telefone Principal', key: 'telePrincipal', width: 15 },
            { header: 'Telefone Opcional', key: 'teleOpcional', width: 15 },
            { header: 'Atividade', key: 'cnae', width: 50 },
            { header: 'CPF', key: 'cpf', width: 15 },
            { header: 'E-mail', key: 'email', width: 25 },
            { header: 'Endereço', key: 'endereco', width: 35 },
            { header: 'Observação', key: 'observacao', width: 35 },
            { header: 'Agencia', key: 'agencia', width: 25 },
            { header: 'Colaborador', key: 'colaborador', width: 35 },
            { header: 'Data Inclusão', key: 'dtInclusao', width: 20 }
        ];

        const dados = prospeccoesFiltrados.map(p => {
            const dado = {
                nome: getNomeCompletoPrimeiraLetraMaiusculo(p.nome),
                telePrincipal: maskTelefone(p.telePrincipal),
                teleOpcional: p.teleOpcional && maskTelefone(p.teleOpcional),
                cnae: p.cnae,
                cpf: p.cpf && maskDocumento(p.cpf),
                email: p.email,
                endereco: p.endereco,
                observacao: p.observacao,
                colaborador: getNomeCompletoPrimeiraLetraMaiusculo(p.colaborador),
                agencia: p.agencia,
                dtInclusao: format(parseISO(p.dtInclusao.toString()), 'dd/MM/yyyy HH:mm:ss'),
            }
            return dado
        })
        await gerarArquivoXLS(
            "Prospeccao",
            dados,
            cabecalho,
            `prospeccao-${format(new Date(), 'yyyy-MM-dd')}`
        )
    }

    useEffect(() => {
        const data: Request = {
            colabId: colabId
        }
        async function loadConfiguracoes(): Promise<void> {
            const response: any = await consultarInfoFiltro(data.colabId);
            if (response && response.data) {
                setUnidades(response.data.agencias)
                setAgentes(response.data.colaboradores)
            }
        }
        async function loadProspeccoes(): Promise<void> {
            const response: any = await consultarProspeccao(data);
            if (response && response.data) {
                setProspeccoesFiltrados(response.data.prospeccoes)
                setProspeccoes(response.data.prospeccoes)
            }
        }

        loadProspeccoes();
        loadConfiguracoes();

    }, []);

    useEffect(() => {

        if ((agenteFiltrado)
            && (unidadeFiltrada)) {

            setIsFiltro(true)
            const filtrados = prospeccoes.filter(item => {

                return item.colabId === Number(agenteFiltrado)
                    && item.agenciaId === Number(unidadeFiltrada)
            })

            setProspeccoesFiltrados(filtrados)


        } else if (agenteFiltrado) {
            setIsFiltro(true)
            const filtrados = prospeccoes.filter(item => {

                return item.colabId === Number(agenteFiltrado)
            })

            setProspeccoesFiltrados(filtrados)
        } else if (unidadeFiltrada) {
            setIsFiltro(true)
            const filtrados = prospeccoes.filter(item => {
                return item.agenciaId === Number(unidadeFiltrada)
            })

            setAgenteFiltrado(agenteFiltrado)
            setProspeccoesFiltrados(filtrados)

        } else {

            setProspeccoesFiltrados(prospeccoes)
            setIsFiltro(false)
        }

    }, [agenteFiltrado, prospeccoes, unidadeFiltrada]);


    return (

        <Container>
            <Menu campaign={false} notify={false} prospeccoes={true} />
            <Body>

                <Filtro>

                    <select onChange={e => handlerFiltrarPorUnidade(e.target.value)}>
                        {unidades.length !== 0 && unidades.length !== 1 &&
                            <option key={-1} value={""}>Selecione uma UAD para filtrar</option>}
                        {unidades.length !== 0 &&
                            unidades.map(item => (<option key={item.id} value={item.id}>{item.nome}</option>))
                        }
                    </select>
                    <select onChange={e => handlerFiltrarPorAgentes(e.target.value)}>
                        <option key={-1} value={""}>Selecione um Agente para filtrar</option>
                        {agentes.length !== 0 &&
                            agentes.map(item => (<option key={item.id} value={item.id}>{item.nome}</option>))
                        }
                    </select>

                    <span onClick={handlerExtrairRelatorio}>
                        <FiDownload size={20} color={'#022751'} />
                        Exportar</span>

                </Filtro>
                {prospeccoesFiltrados && <TableProspeccao prospeccoes={prospeccoesFiltrados} listaFiltradaVazia={isFiltro} />}

            </Body>
        </Container>
    )
}

export default Notificacao;