import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import store from './store';

import Routes from './routes';

import GlobalStyle from './styles/global';

const App: React.FC = () => (
  <>
    <Provider store={store}>
      <GlobalStyle />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Routes />
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  </>
);

export default App;
