import styled from 'styled-components';
import colors from '../../../colors';

export const Container = styled.div`
  box-shadow: 0px 8px 20px #222b4529;
  margin-top:20px;
  margin-bottom:50px;
  color: #000;
  width:100%;

  span{
    color: ${colors.primary};
    font-size:1.2em;
  }

  strong{
    font-weight: bold;
  }


  table{
      display:flex;
      flex-direction: column;
    tr{
      margin-top:10px;
      display:flex;
      align-items:center;
      justify-content:space-evenly;
      /* border: 1px solid rgba(0,0,0,0.3); */
      box-shadow: 1px 2px 4px rgba(0,0,0,0.3);
     
      td:first-child{
        padding-left:50px
      }
    }
  
    tr:first-child{
      display:flex;
      align-items:center;
      justify-content:space-evenly;
      background: ${colors.primary};
      color: #ffff;
      padding-left:50px;
      text-align: center;
      height:2em;

    }



  

  }

`;
export const LoadingDiv = styled.div`
  display: flex;
  height: 6rem;
  justify-content: center;
  align-items: center;
`;