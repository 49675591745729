import React, {
    InputHTMLAttributes,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import { Span } from './style';

interface IProp {
    text : string;
}

const SpanError: React.FC<IProp> = ( { text } ) => {

    return (
            <Span>{text}</Span>
    )

}

export default SpanError;