import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;

  & + div {
    margin-top: 24;
  }

  h1 {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
  }

  ${props =>
    props.isFocused &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #ff9000;
    `}

  textarea {
    display: flex;
    flex: 1;
    min-height: 3.8rem;
    border-radius: 4px;
    background: #f6f7ff;
    color: #5a4d4d;
    font-size: 1.6rem;
    padding: 1rem 0 0 1rem;
    border: 1px solid #dededf;
    font: normal normal 300 1.4rem Roboto;
    overflow: auto;
    resize: none;

    &::placeholder {
      color: #b7b7cc;
    }
  }

  textarea.empty-error {

    border-color: #ff8e7a !important;
  }

  svg {
    margin-right: 16px;
  }
`;
