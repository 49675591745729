import styled from 'styled-components';
import angle from '../../assets/angle.svg'

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 30px;
  width: 80vw;
  min-height: 100vh;
  
`;


export const Filtro = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5rem;
  background: #fff4;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  border: 1px solid #ddd;
  color: #F4EDE8;


  select{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #ddd;
    border-radius: 4px;;
    font-size: 15px;
    padding-right: 25px;
    background: url(${angle}) no-repeat center right #fffd;
    flex:1;
    margin-right: 15px;
    color: #000;
    cursor: pointer;
    &:hover{
      font-size: 16px;
    }       
  }

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #022751;
    width: 120px;
    height: 25px;
    border-radius: 4px;
    background: #ddd;
    cursor: pointer;
    box-shadow: 1px 3px 4px rgba(0,0,0,0.2);
    svg{
      margin-right: 3px;
   
    }

    &:hover{
      font-weight: bold;
      background: #dddd;
    }
  }


  
`;

