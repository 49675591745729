import styled from 'styled-components';
import colors from '../../colors';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 30px;
  width: 80vw;
  min-height: 100vh;
  
`;


export const DataTableDiv = styled.div`
  display: flex;
  margin-top: 3rem;

  p {
    margin-left: 1rem;
  }
`;

export const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(20),
    border: '1px solid #dadde9'
  },
}))(Tooltip);