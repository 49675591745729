import styled from 'styled-components';
import {shade} from 'polished'
import colors from '../../colors';
import { Form } from '@unform/web';

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid rgba(182, 182, 182, 0.3);
  margin-bottom:50px;
  
  p {
    margin: 1rem 0 0.2rem 0;
  }

  span{
    margin-top: 5px;
    color: ${colors.primary};
    font-size: 1em;
  }

  input {
    display: flex;
    border-radius: 5px;
    color: #5a4d4d;
    height: 3.8rem;
    width: 20%;
    padding: 0.3rem 1rem 0.3rem 1rem;
    border: 1px solid #dededf;
    font: normal normal 300 1.4rem Roboto;
    &::placeholder {
      color: #b7b7cc;
    }

    
  }

  input.empty-error {

    border-color: #ff8e7a !important;
  }
`;

export const Titulo = styled.input`
    display: flex;
    flex: 1;
    background: #fff;

`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    background: ${colors.tertiary};
    color: ${colors.primary};
    font-weight: bold;
    border-radius: 0.5rem;
    margin: 1rem 1rem 1rem 1rem;
    font-size: 1.3rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
    box-shadow: 1px 2px 7px 3px #222b4529;

    p {
        margin-left: 1rem;
        font-weight: bold;
        margin-top: 1rem;
    }
    &:hover{
      background: ${shade(0.1, '#FFF')}
    }
`;

export const LoadingDiv = styled.div`
  display: flex;
  flex: 1;
  height: 50%;
  justify-content: center;
  align-items: center;
`;

export const NotificacaoDetailsDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${colors.tertiary};
  padding: 2rem 2rem 0;
  margin: 3rem 0 1rem 0;
  border-radius: 5px;
  box-shadow: 0px 8px 20px #222b4529;
  min-height: 230px;

  p {
    color: ${colors.primary};
    margin-bottom: 1rem;
    font-weight: lighter;
    font-size: 1rem;
    font: normal normal normal 1.4rem Roboto;
  }

  footer {
    align-self: flex-end;
  }
`;

export const ErrorDiv = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  border: 1px solid red;
  color: red;
`;

export const Container = styled.div`
    width:100%;
    margin-right:50px;
    box-shadow: 0px 8px 20px #222b4529;
    color: black !important; 
    margin-bottom: 5px;
    
    div#texto{
        display:flex;
        align-items:center;     
        
    }

    
    span#spanbutton{
        display:flex;
        flex-wrap: wrap;
        flex-direction:column;
        align-items:center;     
        background-color: #e5e5e5;
        
        a{
            font-size:12px;
            color: blue;
            cursor: pointer;
        }
        
        img{
            margin:5px 5px 5px 5px;
            border-radius: 10px;
            width: 100px;
            height: 100px;
            resize: none;
        }
    }

    .ql-toolbar.ql-snow{
        display:flex;
        flex:1;
        width: auto;
        border-radius: 5px 0 0 5px;
        border:0;
        border-bottom:1px solid #E5E5E5;
        border-top:1px solid #E5E5E5;
    }

    .ql-container.ql-snow {
        height: 10em;
        width: 100%;
        border-radius: 5px 0 0 5px;
        border:0;
    }

    .ql-editor {
        width:100%;
        font-weight:normal;
        overflow-y:auto;
    }

    strong {
        font-weight:bold;
    } 

    

`;

export const Previsao = styled.div`
    margin-top:5px;
    display:flex;
    align-items:center;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 20px #222b4529;
    color: #000 !important;
    padding: 10px;
    
    div{
        width:100%;
        height: 11em;
        max-height:17em;
        font-weight:normal;
        overflow-y:auto;
     
      strong {
        font-weight:bold;
      } 

    }

    div#image{
      display: flex;
      align-items: center;
      justify-content: center;
      width:150px;
      height:150px;
      background:#e5e5e5;
      margin-right: 15px;

      p{
        text-align:center;
        font-size: 13px;
        color: blue;
        padding: 5px;
        cursor: pointer;
        &:hover{
          font-size:14px;
          
        }
      }

    }

    div#remover{
      display: flex;
      flex-direction:column;
      justify-content: center;
      width:150px;

        img{
          width:120px;
          height:120px;
          background:#e5e5e5;
          margin-right: 15px;
        }
      p{
        color: blue;
        text-decoration:underline;
        margin-left: 30px;
        &:hover{
          color: #00BFFF;
          font-size: 15px;
          cursor: pointer;
        }
      }
    }

    

`;