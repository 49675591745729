import React, { useState, useEffect, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { useSelector } from 'react-redux';
import Tabs from '../../../components/Tabs';
import { useHistory, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { format } from 'date-fns';
import DeleteModal from '../../../components/Modals/DeleteCampaign';
import SendModal from '../../../components/Modals/SendCampaign';
import FinishModal from '../../../components/Modals/FinishCampaign';
import * as Yup from 'yup';
import { BiArrowBack } from 'react-icons/bi';
import {
  AiOutlineUpload,
  AiOutlineSave,
  AiOutlineCheckCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineSend,

} from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { FaFileCsv } from 'react-icons/fa';
import DatePicker from '../../../components/DatePicker';
import Swal from 'sweetalert2';
import Menu from '../../../components/Menu';
import Table from '../../../components/Table';
import TextArea from '../../../components/TextArea';
import {
  Container,
  Body,
  ImagePickerWrapper,
  ActionButtonsDiv,
  BackButtonDiv,
  Divider,
  CampaignDetailsDiv,
  Select,
  FormWrapper,
  FormatDiv,
  UserInfoDiv,
  ShareInfoDiv,
  RowElementDiv,
  CampaignSituationDiv,
  ImportDiv,
  ConfirmImportButton,
  ButtonsDiv,
  ImportFileDiv,
  LoadingDiv,
  TwoInputsDiv,
  ErrorDiv,
} from './styles';
import colors from '../../../colors';
import { campanhaRoutes } from '../../../services/api';
import { ICampaign, IRootState } from '../../../interfaces';

const Campaign: React.FC = () => {
  const { colabId } = useSelector((state: IRootState) => state.campaign);
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState<any>(null);

  const { id } = useParams();
  // loading for requests
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  // divs control
  const [importing, setImporting] = useState<boolean>(false);
  const [showImports, setShowImports] = useState<boolean>(false);
  const [disable, setDisabled] = useState<boolean>(false);

  // dropdown infos
  const [modalities, setModalities] = useState<any>(null);
  const [products, setProducts] = useState<any>(null);

  // history and records data
  const [importHistory, setImportHistory] = useState<any>([]);
  const [csvRecords, setCsvRecords] = useState<any>(null);

  // modals
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openSendModal, setOpenSendModal] = useState<boolean>(false);
  const [openFinishModal, setOpenFinishModal] = useState<boolean>(false);

  // files
  const [bannerImage, setBannerImage] = useState<any>(null);
  const [campaignImage, setCampaignImage] = useState<any>(null);
  const [topImage, setTopImage] = useState<any>(null);
  const [csvFile, setCsvFile] = useState<any>(null);

  // images url
  const [bannerImageUrl, setBannerImageUrl] = useState<any>('');
  const [campaignImageUrl, setCampaignImageUrl] = useState<any>('');
  const [topImageUrl, setTopImageUrl] = useState<any>('');
  const [bannerImageChanged, setBannerImageChanged] = useState<boolean>(false);
  const [campaignImageChanged, setCampaignImageChanged] = useState<boolean>(
    false,
  );
  const [topImageChanged, setTopImageChanged] = useState<boolean>(false);

  // fields
  const [name, setName] = useState<string>('');
  const [modality, setModality] = useState<string>('');
  const [product, setProduct] = useState<string>('');
  const [notification, setNotification] = useState<string>('');
  const [sms, setSms] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);
  const [interest, setInterest] = useState<any>('');
  const [tac, setTac] = useState<any>('');
  const [description, setDescription] = useState<string>('');

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [editting, setEditting] = useState<boolean>(false);
  const [edittingCampaign, setEdittingCampaign] = useState<ICampaign>();

  // consts
  const disableSave = edittingCampaign
    ? !(edittingCampaign?.status === 'E')
    : false;

  const disableBtns = !(edittingCampaign?.status === 'E');
  const disableFinalize = !(edittingCampaign?.status === 'S');
  const disableSend =
    !(edittingCampaign?.status === 'E' || edittingCampaign?.status === 'S') ||
    importHistory.length === 0;

  const history = useHistory();

  useEffect(() => {
    getCampaign();
    getImportHistory();
    getProductAndModalitiesData();
  }, []);

  useEffect(() => {
    if (edittingCampaign) {
      setName(edittingCampaign?.nome || '');
      setModality(edittingCampaign?.modalidade || '');
      setProduct(edittingCampaign?.produtoId || '');
      setNotification(edittingCampaign?.descricaoEnvio || '');
      setInterest(handleReplace(edittingCampaign?.taxa) || '');
      setTac(handleReplace(edittingCampaign?.tac) || '');
      setSms(edittingCampaign?.sms || false);
      setEmail(edittingCampaign?.email || false);
      setDescription(edittingCampaign?.descricao || '');
      setStartDate(new Date(edittingCampaign?.dtInicio) || '');
      setEndDate(new Date(edittingCampaign?.dtFim) || '');

      if (
        edittingCampaign?.status === 'S' ||
        edittingCampaign?.status === 'F'
      ) {
        setDisabled(true);
      }
    }
  }, [edittingCampaign]);

  function handleChangeModality(e: any) {
    setModality(e.target.value);
  }

  function handleChangeProduct(e: any) {
    setProduct(e.target.value);
  }

  function handleReplace(str: any) {
    const num = Number(str).toFixed(2); // garante que tem 2 decimais
    str = String(num).replace('.', ','); // muda de numero pra string
    return str;
  }

  const handleSubmit = async () => {
    setError(null);
    try {
      const schema = Yup.object().shape({
        campanhaId: Yup.number(),
        nome: Yup.string().required('Informe o campo nome.'),
        descricao: Yup.string().required('Informe o campo descrição.'),
        dtInicio: Yup.string().required('Informe o campo data de inicio.'),
        dtFim: Yup.string().required('Informe o campo data final.'),
        sms: Yup.boolean().required(),
        email: Yup.boolean().required(),
        colabCriador: Yup.number().required(),
        taxa: Yup.number().required('Informe o campo taxa.'),
        tac: Yup.number().required('Informe o campo tac.'),
        produtoId: Yup.string().required('Informe o campo produto.'),
        modalidade: Yup.string().required('Informe o campo modalidade.'),
        status: Yup.string(),
        mosaicoUrl: Yup.string().required('Informe a imagem da campanha.'),
        bannerUrl: Yup.string().required(
          'Informe a imagem de banner da campanha.',
        ),
        topoUrl: Yup.string().required('Informe a imagem de topo.'),
        descricaoEnvio: Yup.string().required(
          'Informe o campo texto de notificação.',
        ),
      });

      let response: any;
      let data: any;
      const dtInicio = startDate ? new Date(startDate).toISOString() : '';
      const dtFim = startDate ? new Date(endDate).toISOString() : '';
      const interestNum = interest.replace(',', '.');
      const tacNum = tac.replace(',', '.');

      if (edittingCampaign) {
        data = {
          campanhaId: edittingCampaign?.campanhaId,
          nome: name,
          descricao: description,
          dtInicio: dtInicio,
          dtFim: dtFim,
          sms: sms,
          email: email,
          colabCriador: colabId,
          taxa: Number(interestNum),
          tac: Number(tacNum),
          produtoId: product,
          modalidade: modality,
          status: edittingCampaign?.status,
          mosaicoUrl: campaignImageChanged
            ? campaignImageUrl
            : edittingCampaign?.mosaicoUrl,
          topoUrl: topImageChanged ? topImageUrl : edittingCampaign?.topoUrl,
          bannerUrl: bannerImageChanged
            ? bannerImageUrl
            : edittingCampaign?.bannerUrl,
          descricaoEnvio: notification,
        };
        await schema.validate(data, { abortEarly: false });

        response = await campanhaRoutes.put(`campanha`, data);
        console.log(response)
      } else {
        data = {
          nome: name,
          descricao: description,
          dtInicio: dtInicio,
          dtFim: dtFim,
          sms: sms,
          email: email,
          colabCriador: colabId,
          taxa: Number(interestNum),
          tac: Number(tacNum),
          produtoId: product,
          modalidade: modality,
          status: 'E',
          mosaicoUrl: campaignImageUrl,
          topoUrl: topImageUrl,
          bannerUrl: bannerImageUrl,
          descricaoEnvio: notification,
        };

        await schema.validate(data, { abortEarly: false });
        response = await campanhaRoutes.post(`campanha`, data);
      }

      setEdittingCampaign(response.data.campanha);
      Swal.fire({
        icon: 'success',
        title: 'Campanha salva com sucesso!',
        showConfirmButton: false,
        timer: 2000,
      });
      history.replace(`/campaign/${response.data.campanha.campanhaId}`);
    } catch (err) {
      console.log(err)
      if (err instanceof Yup.ValidationError) {
        setError(err.errors);
      } else if (err?.response?.data?.message) {
        Swal.fire({
          icon: 'error',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  async function getCampaign() {
    try {
      const isParam = (id !== '0');
      if (isParam) {
        setEditting(true);
        const response = await campanhaRoutes.get(`campanha/${id}`);
        setEdittingCampaign(response.data);
      }
    } catch (err) {
      console.log(err.response);
    }
  }

  async function sendCampaign() {
    try {
      setOpenSendModal(false);
      const data = {
        colabId: colabId,
      };
      const response = await campanhaRoutes.post(
        `campanha/enviar/${edittingCampaign?.campanhaId}`,
        data,
      );
      Swal.fire({
        icon: 'success',
        title: 'Campanha enviada com sucesso!',
        showConfirmButton: false,
        timer: 2000,
      });
      setEdittingCampaign(response.data.campanha);
    } catch (err) {
      setOpenSendModal(false);
      if (err?.response?.data?.message) {
        Swal.fire({
          icon: 'error',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  async function deleteCampaign() {
    try {
      handleOpenDeleteModal();
      await campanhaRoutes.delete(`/campanha/${id}`);
      Swal.fire({
        icon: 'success',
        title: 'Campanha deletada com sucesso!',
        showConfirmButton: false,
        timer: 2000,
      });
      history.goBack();
    } catch (err) {
      handleOpenDeleteModal();
      if (err?.response?.data?.message) {
        Swal.fire({
          icon: 'error',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  const handleFinishCampaign = async () => {
    setError(null);
    handleOpenFinishModal();
    try {
      const schema = Yup.object().shape({
        campanhaId: Yup.number(),
        nome: Yup.string().required('Informe o campo nome.'),
        descricao: Yup.string().required('Informe o campo descrição.'),
        dtInicio: Yup.string().required('Informe o campo data de inicio.'),
        dtFim: Yup.string().required('Informe o campo data final.'),
        sms: Yup.boolean().required(),
        email: Yup.boolean().required(),
        colabCriador: Yup.number().required(),
        taxa: Yup.number().required('Informe o campo taxa.'),
        tac: Yup.number().required('Informe o campo tac.'),
        produtoId: Yup.string().required('Informe o campo produto.'),
        modalidade: Yup.string().required('Informe o campo modalidade.'),
        status: Yup.string(),
        mosaicoUrl: Yup.string().required('Informe a imagem da campanha.'),
        bannerUrl: Yup.string().required(
          'Informe a imagem de banner da campanha.',
        ),
        topoUrl: Yup.string().required('Informe a imagem de topo.'),
        descricaoEnvio: Yup.string().required(
          'Informe o campo texto de notificação.',
        ),
      });

      let response: any;
      let data: any;
      const dtInicio = startDate ? new Date(startDate).toISOString() : '';
      const dtFim = startDate ? new Date(endDate).toISOString() : '';
      const interestNum = interest.replace(',', '.');
      const tacNum = tac.replace(',', '.');

      data = {
        campanhaId: edittingCampaign?.campanhaId,
        nome: name,
        descricao: description,
        dtInicio: dtInicio,
        dtFim: dtFim,
        sms: sms,
        email: email,
        colabCriador: colabId,
        taxa: Number(interestNum),
        tac: Number(tacNum),
        produtoId: product,
        modalidade: modality,
        status: 'F',
        mosaicoUrl: campaignImageChanged
          ? campaignImageUrl
          : edittingCampaign?.mosaicoUrl,
        topoUrl: topImageChanged ? topImageUrl : edittingCampaign?.topoUrl,
        bannerUrl: bannerImageChanged
          ? bannerImageUrl
          : edittingCampaign?.bannerUrl,
        descricaoEnvio: notification,
      };
      await schema.validate(data, { abortEarly: false });

      response = await campanhaRoutes.put(`campanha`, data);

      setEdittingCampaign(response.data.campanha);
      Swal.fire({
        icon: 'success',
        title: 'Campanha finalizada com sucesso!',
        showConfirmButton: false,
        timer: 3000,
      });
      history.replace(`/campaign/${response.data.campanha.campanhaId}`);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.errors);
      } else if (err?.response?.data?.message) {
        Swal.fire({
          icon: 'error',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  async function getImportHistory() {
    try {
      const isParam = (id !== '0');
      if (isParam) {
        const response = await campanhaRoutes.get(`historico/${id}`);
        setImportHistory(response.data);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        Swal.fire({
          icon: 'error',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  async function getProductAndModalitiesData() {
    try {
      const response = await campanhaRoutes.get('infos');
      setProducts(response.data.listaProdutos);
      setModalities(response.data.listaModalidades);
      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);
      if (err?.response?.data?.message) {
        Swal.fire({
          icon: 'error',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 4000,
        });
      }
    }
  }

  function getRawValue(text: string) {
    const auxText = text.replace(/[^0-9]+/g, '');
    return (auxText || '').replace(/\s/g, '');
  }

  const SUPPORTED_FORMATS_IMG = ['image/jpg', 'image/jpeg', 'image/png'];

  async function getImageUrl(image: any): Promise<any> {
    setError(null);
    try {
      const schema = Yup.object().shape({
        image: Yup.mixed().test(
          'fileType',
          'Por favor, escolha uma imagem com os seguintes formatos: jpg, jpeg, png.',
          value => SUPPORTED_FORMATS_IMG.includes(value.type),
        ),
      });
      await schema.validate({ image });

      const formData = new FormData();
      formData.append('recfile', image);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const response = await campanhaRoutes.post(
        `campanha/image/${id}`,
        formData,
        config,
      );
      return response.data;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.errors);
      } else if (err?.response?.data?.message) {
        Swal.fire({
          icon: 'error',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  //const SUPPORTED_FORMATS_FILE = ['text/csv'];

  async function sendCSVFile(file: any) {
    setError(null);
    setLoading(true);
    try {
      // const schema = Yup.object().shape({
      //   file: Yup.mixed().test(
      //     'fileType',
      //     'Por favor, escolha um arquivo .csv.',
      //     value => SUPPORTED_FORMATS_FILE.includes(value.type),
      //   ),
      // });

      //await schema.validate({ file });  // doesnt work on windows

      const fileName = file.name;
      const type = fileName.split('.')[1];

      if (type === 'csv') {
        const formData = new FormData();
        formData.append('recfile', file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        const response = await campanhaRoutes.post(
          `campanha/arquivo/${id}/${colabId}`,
          formData,
          config,
        );
        setLoading(false);
        setCsvRecords(response.data);

        Swal.fire({
          icon: 'success',
          title: 'Arquivo importado com sucesso!',
          showConfirmButton: false,
          timer: 2000,
        });
        getImportHistory();
      } else {
        const errors = ['Por favor, adicione um arquivo csv!!'];
        setError(errors);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        setError(err.errors);
      } else if (err?.response?.data?.message) {
        const errors = [err.response.data.message];
        setError(errors);
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Estamos com problemas no servidor, tente novamente mais tarde.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  function handleNumberMask(str: string) {
    str = getRawValue(str);
    if (str.length > 4) {
      const pos = Math.ceil(str.length / 2);
      str = str.substring(0, pos) + ',' + str.substring(pos, str.length);
    } else if (str.length > 2) {
      const pos = str.length / 2;
      str = str.substring(0, pos) + ',' + str.substring(pos, str.length);
    }

    return str;
  }

  async function onChangeCsvFile(e: any) {
    setCsvFile(e.target.files[0]);
    sendCSVFile(e.target.files[0]);
  }

  function handleConfirmImport() {
    setImporting(false);
    setShowImports(false);
  }

  async function handleBannerImage(file: any) {
    setBannerImage(file);
    const urlBanner = await getImageUrl(file);
    console.log(urlBanner)
    setBannerImageUrl(urlBanner);
    setBannerImageChanged(true);
  }

  async function handleCampaignImage(file: any) {
    setCampaignImage(file);
    const urlCampanha = await getImageUrl(file);
    setCampaignImageUrl(urlCampanha);
    setCampaignImageChanged(true);
  }

  async function handleTopImage(file: any) {
    setTopImage(file);
    const urlTopo = await getImageUrl(file);
    setTopImageUrl(urlTopo);
    setTopImageChanged(true);
  }

  function handleSituation() {
    switch (edittingCampaign?.status) {
      case 'E':
        return 'Em Elaboração';
      case 'S':
        return 'Enviada';
      case 'F':
        return 'Finalizado';
      default:
        return 'Em Elaboração';
    }
  }

  function renderImportDocument() {
    return (
      <ImportDiv>
        {error && (
          <ErrorDiv>
            {error.map((err: any) => (
              <li>{err}</li>
            ))}
          </ErrorDiv>
        )}

        {showImports ? (
          <Table importHistoryList={importHistory} />
        ) : (
          <ImportFileDiv>
            <div>
              <FaFileCsv color="#022751" size={40} />

              <p>{csvFile ? csvFile?.name : 'Escolher arquivo .CSV'}</p>
            </div>

            {loading ? (
              <ReactLoading
                type={'bubbles'}
                color={colors.primary}
                height={'8%'}
                width={'8%'}
              />
            ) : (
              <ButtonsDiv>
                <label>
                  Anexar documento
                  <input
                    disabled={disable}
                    type="file"
                    onChange={onChangeCsvFile}
                    required
                  ></input>
                </label>
                <button onClick={() => setCsvFile(null)}>Limpar</button>
              </ButtonsDiv>
            )}
          </ImportFileDiv>
        )
        }

        {
          !showImports && (
            <>
              <Tabs records={csvRecords} />

              <ConfirmImportButton onClick={handleConfirmImport}>

                Confirmar Importação
              </ConfirmImportButton>
            </>
          )
        }
      </ImportDiv >
    );
  }

  function renderDetailsAndSituation() {
    return (
      <RowElementDiv>
        <CampaignDetailsDiv>
          <FormWrapper ref={formRef} onSubmit={handleSubmit}>
            <div>
              <p>Título:</p>
              <input
                disabled={disable}
                value={name}
                type="text"
                onChange={e => setName(e.target.value)}
                required
                maxLength={50}
                placeholder="O Título da sua campanha deve ter no máximo 250 caracteres"
              />
            </div>

            <p>Descrição da campanha</p>
            <TextArea
              disabled={disable}
              name="description"
              value={description}
              type="text"
              onChange={e => setDescription(e.target.value)}
              placeholder="A descrição deve ter no máximo 250 caracteres"
              required
              maxLength={250}
            ></TextArea>
            <p>Texto de notificação para envio ao usuário</p>
            <TextArea
              disabled={disable}
              name="notification"
              value={notification}
              placeholder="O texto de notificação deve ter no máximo 160 caracteres"
              type="text"
              onChange={e => setNotification(e.target.value)}
              required
              maxLength={160}
            ></TextArea>
            <p>Modalidade</p>
            <Select
              disabled={modalities?.length === 0 || disable}
              onChange={handleChangeModality}
              name="modality"
              value={modality}
            >
              {modalities &&
                modalities.map((modality: any) => (
                  <option
                    key={modality.siglaModalidade}
                    value={modality.siglaModalidade}
                  >
                    {modality.descricao}
                  </option>
                ))}
            </Select>
            <p>Produto</p>
            <Select
              disabled={products?.length === 0 || disable}
              onChange={handleChangeProduct}
              name="product"
              value={product}
              required
            >
              {products &&
                products.map((product: any) => (
                  <option key={product.id} value={product.id}>
                    {product.nome}
                  </option>
                ))}
            </Select>
            <TwoInputsDiv>
              <div>
                <p>Taxa</p>
                <input
                  disabled={disable}
                  name="interest"
                  type="text"
                  value={interest}
                  onChange={e => setInterest(handleNumberMask(e.target.value))}
                  placeholder="8.4%"
                  maxLength={6}
                  required
                />
              </div>

              <div>
                <p>Tac</p>
                <input
                  disabled={disable}
                  type="text"
                  value={tac}
                  onChange={e => setTac(handleNumberMask(e.target.value))}
                  name="tac"
                  placeholder="99.2%"
                  required
                  maxLength={6}
                />
              </div>
            </TwoInputsDiv>
          </FormWrapper>

          <FormatDiv>
            <div>
              <p>Data de início</p>
              <DatePicker
                disabled={disable}
                format="dd/MM/yyyy"
                value={startDate}
                onChange={setStartDate}
                placeholder="10/12/2020"
              />
            </div>
            <div>
              <p>Data final</p>
              <DatePicker
                disabled={disable}
                format="dd/MM/yyyy"
                value={endDate}
                onChange={setEndDate}
                placeholder="10/01/2021"
              />
            </div>
          </FormatDiv>

          <ImagePickerWrapper>
            <div>
              <p>Imagem banner da campanha:</p>
              {edittingCampaign ? (
                <a href={edittingCampaign?.bannerUrl} target="_blank" rel="noopener noreferrer">
                  [IMAGEM JÁ ENVIADA]
                </a>
              ) : (
                <p>[{bannerImage?.name || 'NOME IMAGEM'}]</p>
              )}
            </div>
            <label>
              Anexar imagem
              <input
                disabled={disable}
                type="file"
                onChange={(e: any) => handleBannerImage(e.target.files[0])}
                required
              ></input>
            </label>
          </ImagePickerWrapper>
          <ImagePickerWrapper>
            <div>
              <p>Imagem da campanha:</p>
              {edittingCampaign ? (
                <a href={edittingCampaign?.mosaicoUrl} target="_blank" rel="noopener noreferrer">
                  [IMAGEM JÁ ENVIADA]
                </a>
              ) : (
                <p>[{campaignImage?.name || 'NOME IMAGEM'}]</p>
              )}
            </div>
            <label>
              Anexar imagem
              <input
                disabled={disable}
                type="file"
                onChange={(e: any) => handleCampaignImage(e.target.files[0])}
                required
              ></input>
            </label>
          </ImagePickerWrapper>
          <ImagePickerWrapper>
            <div>
              <p>Imagem do topo:</p>
              {edittingCampaign ? (
                <a href={edittingCampaign?.bannerUrl} target="_blank" rel="noopener noreferrer">
                  [IMAGEM JÁ ENVIADA]
                </a>
              ) : (
                <p>[{topImage?.name || 'NOME IMAGEM'}]</p>
              )}
            </div>
            <label>
              Anexar imagem
              <input
                disabled={disable}
                type="file"
                onChange={(e: any) => handleTopImage(e.target.files[0])}
                required
              ></input>
            </label>
          </ImagePickerWrapper>
          <ShareInfoDiv>
            <div>
              <input
                disabled={disable}
                type="checkbox"
                checked={sms}
                onChange={() => setSms(!sms)}
              />
              <p>Enviar também por SMS</p>
            </div>
            <div>
              <input
                disabled={disable}
                type="checkbox"
                checked={email}
                onChange={() => setEmail(!email)}
              />
              <p>Enviar também por e-mail</p>
            </div>
          </ShareInfoDiv>
        </CampaignDetailsDiv>
        <CampaignSituationDiv>
          <FormWrapper onSubmit={handleSubmit}>
            <FormatDiv>
              <p>Situação:</p>
              <p>{edittingCampaign ? handleSituation() : 'Em elaboração'}</p>
            </FormatDiv>

            <UserInfoDiv>
              <div>
                <p>Usuário FINSOL que criou a campanha:</p>
                <p>{editting && edittingCampaign?.nomeColabCriador}</p>
              </div>
              <div>
                <p>Usuário FINSOL que enviou a campanha:</p>
                <p>{editting && edittingCampaign?.nomeColabSubmeteu}</p>
              </div>
            </UserInfoDiv>

            <FormatDiv>
              <div>
                <p>Data de atualização</p>
                <p>
                  {edittingCampaign &&
                    format(
                      new Date(),
                      'dd/MM/yyyy',
                    )}
                </p>
              </div>
              <div>
                <p>Data de envio</p>
                <p>
                  {edittingCampaign &&
                    format(
                      new Date(),
                      'dd/MM/yyyy',
                    )}
                </p>
              </div>
            </FormatDiv>
          </FormWrapper>

          {error && (
            <ErrorDiv>
              {error.map((err: any) => (
                <li>{err}</li>
              ))}
            </ErrorDiv>
          )}
        </CampaignSituationDiv>
      </RowElementDiv>
    );
  }

  function handleGoBack() {
    history.goBack();
  }

  function handleOpenSendModal() {
    setOpenSendModal(!openSendModal);
  }
  function handleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal);
  }
  function handleOpenFinishModal() {
    setOpenFinishModal(!openFinishModal);
  }
  return (
    <Container>
      <SendModal
        isOpen={openSendModal}
        setIsOpen={handleOpenSendModal}
        actionFunction={sendCampaign}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={handleOpenDeleteModal}
        actionFunction={deleteCampaign}
      />

      <FinishModal
        isOpen={openFinishModal}
        setIsOpen={handleOpenFinishModal}
        actionFunction={handleFinishCampaign}
      />

      <Menu campaign />
      {pageLoading ? (
        <LoadingDiv>
          <ReactLoading
            type={'spin'}
            color={colors.primary}
            height={'8%'}
            width={'8%'}
          />
        </LoadingDiv>
      ) : (
        <Body>
          <header>
            <BackButtonDiv>
              <button onClick={handleGoBack}>
                <BiArrowBack color={colors.tertiary} size={20} />
                <p>Voltar</p>
              </button>
            </BackButtonDiv>
            <ActionButtonsDiv>
              <button onClick={handleSubmit} disabled={disableSave}>
                <AiOutlineSave
                  color={disableSave ? '#707070' : colors.primary}
                  size={20}
                />
                <p>Salvar</p>
              </button>
              <button
                onClick={() => setImporting(!importing)}
                disabled={disableBtns}
              >
                <AiOutlineUpload
                  color={disableBtns ? '#707070' : colors.primary}
                  size={20}
                />
                <p>Importar</p>
              </button>
              <button disabled={disableBtns} onClick={handleOpenDeleteModal}>
                <RiDeleteBin5Line
                  color={disableBtns ? '#707070' : colors.primary}
                  size={20}
                />
                <p>Excluir</p>
              </button>
              <button onClick={handleOpenSendModal} disabled={disableSend}>
                <AiOutlineSend
                  color={disableSend ? '#707070' : colors.primary}
                  size={20}
                />
                <p>Enviar</p>
              </button>

              <button onClick={() => setShowImports(!showImports)}>
                {showImports ? (
                  <>
                    <AiOutlineEyeInvisible color={colors.primary} size={20} />
                    <p>Esconder</p>
                  </>
                ) : (
                  <>
                    <AiOutlineEye color={colors.primary} size={20} />
                    <p>Visualizar</p>
                  </>
                )}
              </button>

              <button
                disabled={disableFinalize}
                onClick={handleOpenFinishModal}
              >
                <AiOutlineCheckCircle
                  color={disableFinalize ? '#707070' : colors.primary}
                  size={20}
                />
                <p>Finalizar</p>
              </button>
            </ActionButtonsDiv>
          </header>

          <Divider />

          {importing || showImports
            ? renderImportDocument()
            : renderDetailsAndSituation()}
        </Body>
      )}
    </Container>
  );
};

export default Campaign;
