import { createActions, createReducer } from 'reduxsauce';
import produce from 'immer';
import { INotificationObject } from '../../../interfaces';

/**
 * Creating action types e creators
 */

export const { Types, Creators } = createActions({

    getNotificationsRequest: [],
    getNotificationsSuccess: ['notifications'],
    getNotificationsFailure: [],

});

/**
 * Creating the reducer handlers
 */
const INITIAL_STATE: INotificationObject = {
    loading: false,
    notificacao: [],
    erro: false,
    isLogged: false,
    colabId: '',
    user: {},
    loginError: '',
};

const getNotificationsRequest = (state = INITIAL_STATE): INotificationObject =>
    produce(state, draft => {
        draft.loading = true;
    });

const getNotificationsSuccess = (
    state = INITIAL_STATE,
    action: any,
): INotificationObject =>
    produce(state, draft => {
        draft.loading = false;
        draft.notificacao = action.notifications.notificacao;
        draft.erro = action.notifications.erro;
    });

const getNotificationsFailure = (state = INITIAL_STATE): INotificationObject =>
    produce(state, draft => {
        draft.loading = false;
    });

export default createReducer(INITIAL_STATE, {

    [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
    [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
    [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,

});
