import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Container } from './styles';
import { IRecordLine } from '../../interfaces';
import colors from '../../colors';

interface IProps {
  recordList: IRecordLine[];
}

const Records: React.FC<IProps> = ({ recordList }) => {
  return (
    <Container>
      {recordList ? (
        recordList.length > 0 ? (
          recordList.map(record => (
            <div>
              <p>{record?.cpf}</p>
              <p> | {record?.nome}</p>
              {record.isFalha && <p> | {record?.mensagensFalhaValidacao[0]}</p>}
            </div>
          ))
        ) : (
          <span>Não há registros</span>
        )
      ) : (
        <span>Importe um arquivo csv para ver os registros aqui.</span>
      )}
    </Container>
  );
};

export default Records;
