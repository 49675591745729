import styled from 'styled-components';
import colors from '../../../colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  background: ${colors.primary};
  align-items: center;
  flex-direction: column;
  justify-content: center;

  footer {
    color: #99a8b9;
    font-size: 1rem;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 1rem;
    z-index: auto;
  }
`;

export const Stars = styled.img`
  height: 20rem;
  width: 20rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 35rem;
  justify-content: space-around;
  flex-direction: column;
  padding: 2rem;

  p {
    font-size: 2rem;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  img {
    height: 10rem;
  }

  button {
    background: transparent linear-gradient(90deg, #ffa344 0%, #ffd62e 100%) 0%
      0% no-repeat padding-box;
    border-radius: 5rem;
    height: 6rem;
    width: 25rem;
    justify-content: center;
    align-items: center;

    p {
      font-weight: bold;
      margin-right: 0.5rem;
      font-family: Roboto;
    }
  }
`;

export const LoginDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
