import styled from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
  background: ${colors.tertiary};
  box-shadow: 0px 8px 20px #222b4529;

  table {
    display: flex;
    flex: 1;
    flex-direction: column;

    tr:first-child {
      display: flex;
      border-radius: 4px;
      font-size: 1.2rem;
      color: #000000cc;
      width: 100%;
      background: #e4e8f1;

      th {
        display: flex;
        padding: 0.4rem 0 0.4rem 0;

        width: 14rem;
      }
    }

    tr {
      color: #000000cc;
      font-weight: lighter;
      font-size: 1.3rem;
      padding: 0.4rem 2rem 0.4rem 2rem;

      td {
        width: 14rem;
      }
    }

    a {
      color: #000000cc;
    }
  }
`;
