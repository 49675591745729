import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlusCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';

import CampaignItem from '../../../components/CampaignItem';
import { Creators as CampaignActions } from '../../../store/ducks/campaign';
import Menu from '../../../components/Menu';

import { IRootState } from '../../../interfaces';
import {
  Container,
  Body,
  NewCampaignDiv,
  CampaignsDiv,
  LoadingDiv,
  EmptyDiv,
} from './styles';
import colors from '../../../colors';

const Dashboard: React.FC = () => {
  const { getCampaignsRequest } = CampaignActions;

  const { campaigns, user, loading } = useSelector(
    (state: IRootState) => state.campaign,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadCampaigns(): Promise<void> {
      dispatch(getCampaignsRequest());
    }
    loadCampaigns();
  }, []);

  function openCampaign(id: number) {
    history.push(`campaign/${id}`);
  }

  function NewCampaign(id: number) {
    history.push(`campaign/${id}`);
  }

  return (
    <Container>
      <Menu campaign={true} />
      <Body>
        <NewCampaignDiv>
          <button onClick={() => NewCampaign(0)}>
            <FaPlusCircle size={20} />
            <p>Nova campanha</p>
          </button>
        </NewCampaignDiv>
        {loading ? (
          <LoadingDiv>
            <ReactLoading
              type={'spin'}
              color={colors.primary}
              height={'10%'}
              width={'10%'}
            />
          </LoadingDiv>
        ) : campaigns.length !== 0 ? (
          <CampaignsDiv>
            {campaigns &&
              campaigns.map(campaign => (
                <CampaignItem
                  key={campaign.campanhaId}
                  campaign={campaign}
                  openCampaign={openCampaign}
                />
              ))}
          </CampaignsDiv>
        ) : (
          <EmptyDiv>Não há campanhas</EmptyDiv>
        )}
      </Body>
    </Container>
  );
};

export default Dashboard;
