import React from 'react';

import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './Route';
import Dashboard from '../pages/Dashboard/Campaigns';
import Campaign from '../pages/Dashboard/Campaign';
import Login from '../pages/Auth/Login';
import Notificacao from '../pages/Notificacao';
import RelatAcessos from '../pages/RelatorioAcessos';
import Prospeccao from '../pages/Prospeccao'

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <PrivateRoute path="/campaigns" isPrivate allowed={'MOD:CAMP'} component={Dashboard} />
    <PrivateRoute path="/campaign/:id" isPrivate allowed={'MOD:CAMP'} component={Campaign} />
    <PrivateRoute path="/notify" isPrivate allowed={'MOD:CAMP'} component={Notificacao} />
    <PrivateRoute path="/relatAcessos" isPrivate allowed={'MOD:CAMP'} component={RelatAcessos} />
    <PrivateRoute path="/prospeccao" isPrivate allowed={'MOD:PROSP'} component={Prospeccao} />
  </Switch>
);

export default Routes;
