import React from 'react';
import { format } from 'date-fns';
import { Container } from './styles';
import { ICampaignImportHistory } from '../../interfaces';

interface IProps {
  importHistoryList: ICampaignImportHistory[];
}

const Table: React.FC<IProps> = ({ importHistoryList }: IProps) => {
  return (
    <Container>
      <table>
        <tr>
          <th>Data</th>
          <th>Aprovados</th>
          <th>Reimportados</th>
          <th>Rejeitados</th>
          <th>Usuário</th>
          <th>Download</th>
        </tr>
        {importHistoryList && importHistoryList.length > 0 ? (
          importHistoryList.map(record => (
            <tr>
              <td>{format(new Date(record?.dtImportacao), 'dd/MM/yyyy')}</td>
              <td>{record?.registrosAceitos}</td>
              <td>{record?.registrosReimportados}</td>
              <td>{record?.registrosRejeitados}</td>
              <td>{record?.colaborador}</td>
              <td>
                <a href={record?.url_csv} target="blank">
                  Link
                </a>
              </td>
            </tr>
          ))
        ) : (
          <span>Não há historico de importações</span>
        )}
      </table>
    </Container>
  );
};

export default Table;
