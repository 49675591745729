import React from 'react';
import { Container, LoadingDiv } from './styles';
import { INotification } from '../../../interfaces/index'
import ReactLoading from 'react-loading';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

interface IProps {
  notificacoes: INotification[];
}

const TableNotificacao: React.FC<IProps> = ({ notificacoes }: IProps) => {
  return (
    <Container>
      <span>Notificações enviadas</span>
      <table>
        <tr>
          <th>Texto da Notificação</th>
          <th>Colaborador</th>
          <th>Data de Envio</th>
        </tr>
        {notificacoes && notificacoes.length > 0 ? (
          notificacoes.map(notificacao => (
            <tr>
              <td width='50%'>
                <>
                  <ReactQuill
                    value={notificacao.texto}
                    theme='bubble'
                    readOnly
                  />
                </>
              </td>
              <td width='25%'>{notificacao.nomeColab}</td>
              <td width='25%'>{notificacao.dataEnvioN}</td>

            </tr>
          ))
        ) : (
          <LoadingDiv>
            <ReactLoading
              type={'spin'}
              color={'black'}
              height={'2%'}
              width={'2%'}
            />
          </LoadingDiv>
        )}
      </table>
    </Container>
  );
};

export default TableNotificacao;
