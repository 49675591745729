import styled from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  color: ${colors.primary};
  font-size: 1.3rem;

  div {
    margin-bottom: 0.5rem;
    p {
      margin-right: 0.5rem;
      color: ${colors.primary};
      font-weight: 500;
    }
    display: flex;
  }

  span {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 1.5rem;
  }
`;
