import { all, put, call, takeLatest } from 'redux-saga/effects';
import { Creators as NotificationsActions, Types } from '.';
import { notificacaoRoutes} from '../../../services/api';


export function* getNotifications() {
  const { getNotificationsSuccess, getNotificationsFailure } = NotificationsActions;
  try {
      const response = yield call(notificacaoRoutes.get, '/');
      yield put(getNotificationsSuccess(response.data));
  } catch (error) {
      yield put(getNotificationsFailure());
  }
}

export default all([

  takeLatest(Types.GET_NOTIFICATIONS_REQUEST, getNotifications),
]);
