export enum ListaFuncoes {
    'MOD:CAMP' = 'MOD:CAMP',
    'MOD:PROSP'= 'MOD:PROSP',
    'MOD:EMPTY' = 'MOD:EMPTY' 
}

const permissaoRoute = ():string => {
    const func = sessionStorage.getItem('@Finsol-web/func')
    
    if(!func){
        return '/'
    }

    const permissoes:string[] = JSON.parse(func);

    if(permissoes.includes('MOD:CAMP')){
        return '/campaigns'
    }else if (permissoes.includes('MOD:PROSP')){
        return '/prospeccao'
    }
    return '/'
}

const permissoesMenu = (itemMenu:string):boolean =>{
    const func = sessionStorage.getItem('@Finsol-web/func')
    
    if(!func){
        return false
    }

    const permissoes:string[] = JSON.parse(func);
    return permissoes.includes(itemMenu)
}

export {permissaoRoute, permissoesMenu}