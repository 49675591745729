import { all, put, call, takeLatest } from 'redux-saga/effects';
import { Creators as CampaignsActions, Types } from '.';
import { campanhaRoutes, notificacaoRoutes, relatorioAcessoRoutes } from '../../../services/api';

export function* loginRequest({user}: any) {
  const { loginSuccess, loginFailure } = CampaignsActions;
  
  try {
   
    const data = {
      email: user?.profileObj?.email,
      token: user.tokenId,
    };
    const response = yield call(campanhaRoutes.post, '/session', data);
    const token = response.data.token;
    const permissoes:string[] = response.data.permissoes || ['MOD:EMPTY']
    
    sessionStorage.setItem('@Finsol-web/token', token);
    sessionStorage.setItem('@Finsol-web/g-user', JSON.stringify(user));
    sessionStorage.setItem('@Finsol-web/func', JSON.stringify(permissoes))
    campanhaRoutes.defaults.headers.common['x-access-token'] = token;
    notificacaoRoutes.defaults.headers.common['x-access-token'] = token;
    relatorioAcessoRoutes.defaults.headers.common['x-access-token'] = token;

    yield put(loginSuccess(user, response.data.colabId));
  } catch (error) {
    yield put(loginFailure(error?.response?.data));
  }
}

export function* logoutRequest() {
  const { logoutSuccess, logoutFailure } = CampaignsActions;
  try {
    delete campanhaRoutes.defaults.headers.common['x-access-token'];
    delete  notificacaoRoutes.defaults.headers.common['x-access-token'];
    delete  relatorioAcessoRoutes.defaults.headers.common['x-access-token'];
    sessionStorage.removeItem('@Finsol-web/token');
    sessionStorage.removeItem('@Finsol-web/g-user');
    sessionStorage.removeItem('@Finsol-web/func');
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutFailure());
  }
}

export function* getCampaigns() {
  const { getCampaignsSuccess, getCampaignsFailure } = CampaignsActions;
  try {
    const response = yield call(campanhaRoutes.get, '/campanhas');
    yield put(getCampaignsSuccess(response.data));
  } catch (error) {
    yield put(getCampaignsFailure());
  }
}

export function* addCampaign({ campaign }: any) {
  const { addCampaignSuccess, addCampaignFailure } = CampaignsActions;
 
  try {
    const response = yield call(campanhaRoutes.post, '/campanha', campaign);
    yield put(addCampaignSuccess(response.data));
  } catch (error) {
    yield put(addCampaignFailure());
  }
}

export function* updateCampaign({ campaign, id }: any) {
  const { updateCampaignSuccess, updateCampaignFailure } = CampaignsActions;
  try {
    const response = yield call(campanhaRoutes.put, `/campanha/${id}`, campaign);
    yield put(updateCampaignSuccess(response.data));
  } catch (error) {
    yield put(updateCampaignFailure());
  }
}

export function* deleteCampaign({ id }: any) {
  const { deleteCampaignSuccess, deleteCampaignFailure } = CampaignsActions;
  try {
    yield call(campanhaRoutes.delete, `/campanha/${id}`);
    yield put(deleteCampaignSuccess(id));
  } catch (error) {
    yield put(deleteCampaignFailure());
  }
}

export default all([
  //login
  takeLatest(Types.LOGIN_REQUEST, loginRequest),

  //logout
  takeLatest(Types.LOGOUT_REQUEST, logoutRequest),

  //campains
  takeLatest(Types.GET_CAMPAIGNS_REQUEST, getCampaigns),
  takeLatest(Types.ADD_CAMPAIGN_REQUEST, addCampaign),
  takeLatest(Types.UPDATE_CAMPAIGN_REQUEST, updateCampaign),
  takeLatest(Types.DELETE_CAMPAIGN_REQUEST, deleteCampaign),
]);
